import { create } from "css-in-bun" with {
    type: "macro"
};
export const common = create({
    Modal: {
        backgroundColor: "var(--background-color)",
        backgroundImage: "radial-gradient(circle at top, #777c, transparent 80%)",
        gridTemplate: "'header' auto 'content' minmax(0, 1fr) / 1fr",
        display: "grid"
    },
    Content: {
        gridArea: "content",
        contain: "strict",
        overflowY: "auto"
    },
    ContentAnimation: {
        maskImage: "radial-gradient(circle at top, black var(--percent), rgba(0, 0, 0, 0) calc(var(--percent) * 1.2))",
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: "max(16px, var(--safe-area-inset-bottom, env(safe-area-inset-bottom)))"
    }
});
